import * as React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const SectionHeader = ({ data, variant, className }) => {
    return (
        <header
            className={`c-section__header${
                variant && ` c-section__header--${variant}`
            }`}
        >
            {data.title && (
                <h2
                    className={`c-section__title ${
                        className && `${className}__title`
                    }`}
                >
                    {data.title}
                </h2>
            )}

            {data.description && (
                <div
                    className="c-section__subtext"
                    dangerouslySetInnerHTML={{ __html: data.description }}
                />
            )}
        </header>
    )
}

SectionHeader.propTypes = {
    /**
     * Data
     */
    data: PropTypes.object.isRequired,
    /**
     * Specify a different style variant
     */
    variant: PropTypes.oneOf(['', 'centered']),
    /**
     * Optional CSS classes
     */
    className: PropTypes.string
}

SectionHeader.defaultProps = {
    data: {
        title: 'Section Title',
        description: '<p>Section description</p>'
    },
    variant: '',
    className: ''
}

export default SectionHeader
